import { isVowel } from '../../utilities/string';

const getDeepValue = (objectRoot, propertySegements) => {
  const [current, ...rest] = propertySegements;

  if (!rest.length || !objectRoot[current]) {
    return objectRoot[current];
  }

  return getDeepValue(objectRoot[current], rest);
};

const getValue = (objectRoot, property) => {
  const splitProperty = property.split('.');

  return getDeepValue(objectRoot, splitProperty);
};

const conditionalValidators = (conditional, validators, report, touched) => {
  if (!getValue(touched, conditional)) {
    return;
  }

  return validators.flat();
}

const minimumPanelValidator = ({ id, panels, minimum = 1 }, report) => {
  const completed = panels.filter(({ name }) => {
    return report[name] && report[name].completed;
  }).length;

  if (completed) {
    return;
  }

  return [id, `You must complete at least ${minimum} section`];
};

const valueListValidator = ({ name, label = name, minimumValues = 0 }, nameRoot, report) => {
  const sectionRoot = getValue(report, nameRoot.substring(0, nameRoot.length - 1));

  if (!minimumValues) {
    return;
  }

  if (!sectionRoot) {
    return [`${nameRoot}${name}`, `You must specify at least one ${label}`];
  }

  const matchingValues = Object.entries(sectionRoot).filter(([property, value]) => property.startsWith(name) && typeof value !== 'undefined' && value !== '');

  if (!matchingValues.length) {
    return [`${nameRoot}${name}`, `You must specify at least one ${label}`];
  }
}

const getValidators = (section, nameRoot = '') => {
  if (section.type === 'fieldSet') {
    return section.fields.reduce((all, { name, label, required, validate, type }) => {
      if (required || validate || type !== 'text') {
        all.push({ name: `${nameRoot}${name}`, label, required, validate, type });
      }

      return all;
    }, []);
  }

  if (section.type === 'panelSelector') {
    return [
      minimumPanelValidator.bind(undefined, section),
      ...section.panels
        .map(panel => ({
          conditional: conditionalValidators.bind(undefined, panel.name, panel.sections.map(panelSection => getValidators(panelSection, `${panel.name}.`))),
        })),
    ];
  }

  if (section.type === 'matrix') {
    const { name, columns, rows, required, validate } = section;
    const cells = Array(columns).fill(Array(rows).fill(undefined));

    const validators = cells.map((cell, cellI) => cell.map((row, rowI) => ({
      name: `${nameRoot}${name}[${cellI}][${rowI}]`,
      label: `Column ${cellI + 1}, Row ${rowI + 1} value`,
      required,
      type: validate,
    }))).flat();

    return validators;
  }

  if (section.type === 'valueList') {
    return [valueListValidator.bind(undefined, section, nameRoot)]
  }

  return [];
};

const predefinedValidators = {
  regex: ({ name, validate: { regex, error } }, value) => {
    return new RegExp(regex).exec(value)
      ? undefined
      : [name, error];
  }
};

const processValidators = (validators, report, touched) => validators.reduce((errors, validator) => {
  if (validator.conditional) {
    return [
      ...errors,
      ...processValidators(validator.conditional(report, touched) || [], report, touched),
    ];
  }

  if (typeof validator === 'function') {
    const error = validator(report, touched);

    if (error) {
      errors.push(error);
    }

    return errors;
  }

  const {
    name,
    label = name,
    required = false,
    validate = () => true,
  } = validator;
  const value = getValue(report, name);

  if (validate.type) {
    const validatorResult = predefinedValidators[validate.type](validator, value);

    if (validatorResult) {
      errors.push(validatorResult);
    }

    return errors;
  }

  if (required && (value === '' || typeof value === 'undefined')) {
    errors.push([name, `You must enter ${isVowel(label[0]) ? 'an' : 'a'} ${label}`]);
    return errors;
  }

  const validationMessage = validate(report[name]);

  if (typeof validationMessage !== 'undefined' && validationMessage !== true) {
    errors.push([name, validationMessage]);
    return errors;
  }

  return errors;
}, []);

export default (config) => {
  const steps = config.steps
    .map(({ sections }) => sections.map((section) => getValidators(section)).flat());

  return (report, stepNumber, touched) => processValidators(steps[stepNumber], report.steps[stepNumber], touched);
};