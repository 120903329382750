import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './form-field.css';

const FormErrorMessage = ({ errors, touched, name, submitted }) => !errors[name] || (!touched[name] && !submitted)
  ? <></> 
  : <div className="form-control-validation-message">
    <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
    {errors[name]}
  </div>;

export default ({
  errors,
  touched,
  submitted,
  name,
  label,
  fieldClassName,
  ...fieldProps
}) => {
  const formField = <input
    {...fieldProps}
    name={name}
    className={`form-control${fieldClassName ? ` ${fieldClassName}` : ''}`} />;

  const fieldWithLabel = <label className="form__item__label">
    {label}<br/>
    {formField}
  </label>;

  return <div className={'form__item__outer' + (errors[name] && (touched[name] || submitted) ? ' error' : '')}>
      <div className="form__item">
        {label ? fieldWithLabel : formField}
      </div>
      <FormErrorMessage errors={errors} touched={touched} submitted={submitted} name={name} />
    </div>;
};
