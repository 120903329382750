import React, { useState, useEffect, useReducer } from 'react';

import { faSave, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ResizeDetector from 'react-resize-detector';
import { API } from "aws-amplify";
import { DateTime } from 'luxon';
import { Redirect } from 'react-router-dom';

import renderSection from './form-components';
import SubmitButton from '../../components/button/submit-button';
import Loading from '../../components/loading';
import createValidator from './validator';
import './edit-report.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const createMatrixInitialValues = ({ columns, rows }) => {
  return Array(columns).fill(Array(rows).fill(undefined).map(() => 0.0));
};

const getFieldsFromSection = (section) => {
  const { type, ...sectionConfig } = section;

  if (type === 'fieldSet') {
    return [...sectionConfig.fields];
  }

  if (type === 'panelSelector') {
    const { panels } = sectionConfig;

    return panels.reduce((all, { name, sections }) => {
      const namePrefix = name ? `${name}.` : '';

      return [
        ...all,
        ...sections.map(getFieldsFromSection)
          .flat()
          .map(({ name, ...rest }) => ({ name: `${namePrefix}${name}`, ...rest })),
      ];
    }, []);
  }

  if (type === 'matrix') {
    const rows = createMatrixInitialValues(sectionConfig);

    return rows.reduce((all, columns, rowIndex) => [
      ...all,
      ...columns.map((value, columnIndex) => ({
        name: `${sectionConfig.name}[${rowIndex}][${columnIndex}]`,
        type: 'number',
        required: true,
        defaultValue: sectionConfig.defaultValue,
      })),
    ], []);
  }

  if (type === 'valueList') {
    return Array(sectionConfig.values).fill(undefined).map((row, i) => ({
      name: `${sectionConfig.name}[${i}]`,
      type: 'number',
      defaultValue: '',
    }));
  }

  return [];
};

const setDeepValue = (root, remainingSegments, value) => {
  const [current, ...remaining] = remainingSegments;

  if (remaining.length) {
    if (!root[current]) {
      root[current] = {};
    }
    
    return setDeepValue(root[current], remaining, value);
  }

  root[current] = value;

  return root;
};

const setReportValue = (report, name, value) => setDeepValue(report, name.split('.'), value);

const defaultValueGenerators = {
  'today': () => DateTime.local().toISODate(),
};

const createInitialReport = (schema) => {
  const { steps } = schema;

  return {
    schema,
    touched: {},
    report: {
      steps: steps.map(({ sections }) => sections
        .map(section => getFieldsFromSection(section)
          .map(({ name, defaultValue }) => {
              let value;

              if (typeof defaultValue === 'undefined') {
                value = '';
              } else if (typeof defaultValue === 'function') {
                value = defaultValue();
              } else if (defaultValueGenerators[defaultValue]) {
                value = defaultValueGenerators[defaultValue]();
              } else {
                value = defaultValue;
              }
  
              return [name, value];
            }))
          .flat()
          .reduce((all, [name, value]) => {
            setReportValue(all, name, value);
            return all;
          }, {})),
    },
  };
};

const ReportFormContainer = ({ currentStep, isSubmitting, submitted, submitError, errors, ...props }) => {
  const [focus, setFocus] = useState('');

  const mobileMode = props.width < 680;
  const sections = currentStep.sections.filter(section => !mobileMode || !focus || focus === section.id);

  return <div className={mobileMode ? 'edit-report__mobile-view' : ''}>
    {sections.map((section, i) => renderSection(section, {
        mobileMode,
        setFocus,
        index: i,
        submitted,
        errors,
        ...props
      }))}
    {focus && mobileMode ? <></> : <div className="edit-report__submit-container">
      {(submitError || Object.keys(errors).length) && submitted
        ? <div className="edit-report__submit-container__error">
            <FontAwesomeIcon icon={faExclamationTriangle} /> {submitError || 'Report Contains errors, you must resolve them before you can submit'}
          </div>
        : <></>}
      <SubmitButton submitted={isSubmitting} icon={faSave} text="Submit Report" />
    </div>}
  </div>;
};

export default () => {
  const [stepNumber] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();
  const [state, dispatch] = useReducer((state, { event, name, value, ...rest }) => {
    if (event === 'schemaLoaded') {
      return { schemaLoaded: true, ...createInitialReport(rest.schema) };
    }

    const { touched } = state;

    touched[name] = true;

    const newState = { ...state, touched };

    if (event === 'completed') {
      return { ...state, completed: true };
    }
    
    if (event === 'touched') {
      return newState;
    }

    setReportValue(newState.report.steps[stepNumber], name, value);

    return newState;
  }, {});

  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        try {
          const schema = await API.get('measureitnow', url);

          dispatch({ event: 'schemaLoaded', schema });
        } catch (e) {
        }
      };
      getData();
    }, [url]);
  }

  useFetch('report-template');

  if (!state.schemaLoaded) {
    return <Loading />;
  }

  if (state.completed) {
    return <Redirect to={`/report/${state.report.steps[0].reference}`} />
  }

  const validator = createValidator(state.schema);

  const currentStep = state.schema.steps[stepNumber];

  const { report, touched = {} } = state;

  const validate = () => {
    const errors = Object.fromEntries(validator(report, stepNumber, touched));

    return errors;
  };

  const errors = validate();

  return <>
    <div className="report-edit__form-container">
      <form onSubmit={async (e) => {
        e.preventDefault();

        if (isSubmitting) {
          return;
        }

        setIsSubmitting(true);
        setSubmitted(true);

        const errors = validate();

        if (Object.keys(errors).length) {
          setIsSubmitting(false);
          return;
        }

        try {
          await API.post('measureitnow', 'report', {
            body: {
              version: state.schema.version,
              reference: report.steps[0].reference,
              ...report
            },
          });

          dispatch({ event: 'completed' });
        } catch (e) {
          if (e.response && e.response.status && e.response.status === 400 && e.response.data.message) {
            setSubmitError(e.response.data.message);
            setIsSubmitting(false);

            return;
          }

          setSubmitError('Unknown error submitting report, please try again');
          setIsSubmitting(false);
        }
      }}>
        <ResizeDetector handleWidth refreshMode="throttle" refreshRate={250}>
          <ReportFormContainer
            report={report.steps[stepNumber]}
            errors={errors}
            touched={touched}
            currentStep={currentStep}
            dispatch={dispatch}
            validateForm={validate}
            submitted={submitted}
            isSubmitting={isSubmitting}
            submitError={submitError}
            events={{
              onCompletion: (completedTab) => dispatch({ name: `${completedTab}.completed`, value: true }),
            }}
            onChange={({ name, value }) => dispatch({ name, value })}
          />
        </ResizeDetector>
      </form>
    </div>
  </>;
};
