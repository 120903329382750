import React from 'react';

import FormField from '../../../components/form/standard-form-field';

export default ({ title, fields = [], report, errors = {}, touched = {}, submitted, dispatch, onChange = () => {} }) => <div className="edit-report__field-set">
  {title ? <h3>{title}</h3> : <></>}
  <div className="edit-report__field-set__items">
    {fields.map(({ name, type, label }) => <div key={`fieldset__field__${name}`} className="edit-report__field-set__item">
      <FormField
        key={name}
        errors={errors}
        touched={touched}
        submitted={submitted}
        type={type}
        label={label}
        name={name}
        value={report[name]}
        onChange={(e) => dispatch({ name, value: e.target.value })}
        onBlur={(e) => dispatch({ event: 'touched', name })} />
    </div>)}
  </div>
</div>;
